import { NextSeo } from 'next-seo';

import { FaktenFuchsBadge } from '@/components/FaktenFuchsBadge';
import { useEnvironment } from '@/cutils/context/EnvironmentContext';
import { notNullOrUndefined } from '@/cutils/misc/notNullOrUndefined';
import { buildBoardSeoConfig } from '@/cutils/seo/buildBoardSeoConfig';
import { buildBoardStructuredData } from '@/cutils/seo/schema-org/buildBoardStructuredData';
import { TrackEventDataType } from '@/types/tracking';
import { PageView, Tracktor } from 'react-tracktor';
import { BoardData } from 'src/pages/[[...board]]';

import { hasHero } from './Board.utils';
import { BoardSection } from './BoardSection';
import BoardSectionItemRichText from './Section/Items/BoardSectionItemRichText';

type Props = {
	data: BoardData;
};

export function Board({ data }: Props): JSX.Element {
	const {
		constants: { appUrl },
	} = useEnvironment();

	const { allShortnews, board, newsVideo } = data;

	const mappedBoardSections = board.boardSections.nodes.filter(notNullOrUndefined).map((boardSection, index) => {
		return (
			<Tracktor
				eventData={{ data: { type: TrackEventDataType.BoardSectionVisible } }}
				key={boardSection.rowId}
				trackingData={{
					data: { sectionPlacement: index },
					event: { type: 'PublisherEvent' },
				}}
			>
				{({ intersectionRef }) => (
					<BoardSection
						ref={intersectionRef}
						boardSection={boardSection}
						newsVideo={newsVideo}
						rundschau={board?.rundschau}
						shortnewsItems={allShortnews}
					/>
				)}
			</Tracktor>
		);
	});

	const seoConfig = buildBoardSeoConfig(board, appUrl);
	const structuredData = buildBoardStructuredData(board, appUrl);

	return (
		<>
			{/* Injects dynamic meta tags into the `<head />`. */}
			<NextSeo {...seoConfig} />

			{/* Injects script tags with structured data. */}
			{structuredData}

			<h1 className="srOnly">{board.title}</h1>

			{!hasHero(board) && (
				<BoardSectionItemRichText
					introductionHeadline={board.introductionHeadline}
					introductionText={board.introductionText}
					hasIntroductionText={board.hasIntroductionText}
				/>
			)}

			<PageView
				key={board.shareUrl}
				pageViewData={{ data: board.trackingInfos || {}, event: { page: board.shareUrl, type: 'PageViewEvent' } }}
			/>

			<Tracktor trackingData={{ data: board.trackingInfos || {} }}>{mappedBoardSections}</Tracktor>

			<FaktenFuchsBadge boardRowId={data.board.rowId} />
		</>
	);
}
