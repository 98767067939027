import { Link } from '@/components/Link/Link';
import buildAuthorName from '@/cutils/builders/buildAuthorName';
import buildAuthorProfileLink from '@/cutils/builders/buildAuthorProfileLink';

import styles from './BoardSectionItemAuthor.module.scss';
import { BoardSectionItemAuthorFragment } from './__generated__/BoardSectionItemAuthor.generated';

type Props = {
	author: BoardSectionItemAuthorFragment;
};

const BoardSectionItemAuthor = ({ author }: Props) => {
	const authorName = buildAuthorName(author);
	const authorProfileLink = buildAuthorProfileLink(author);

	if (!authorName) {
		return null;
	}

	if (!authorProfileLink) {
		return (
			<li className={styles.listItem}>
				<span aria-label="Autor*in">{authorName}</span>
			</li>
		);
	}

	return (
		<li className={styles.listItem}>
			<Link className={styles.authorLink} aria-label="Autor*in" title={`Zum Profil von ${authorName}`} href={authorProfileLink}>
				<div className={`body6 ${styles.name}`}>{authorName}</div>
			</Link>
		</li>
	);
};

export default BoardSectionItemAuthor;
